import React from 'react';
import { PadlockIconMini } from './PadlockIconMini';
import PropTypes from 'prop-types';

const BadgeCurrentLevel = ({ level, selectedAchievementBadge }) => {
  if (!selectedAchievementBadge) {
    return null;
  }

  return (
    <div className={`selected-badge-current-level ${selectedAchievementBadge?.badgeData?.progressive ? 'progressive' : ''}`}>
      {selectedAchievementBadge?.badgeData?.progressive &&
      (selectedAchievementBadge?.type === 'login_streak' || selectedAchievementBadge?.type === 'payout_milestone') ? (
        <p>
          Level{' '}
          <span className="lvl-number">
            {level && selectedAchievementBadge?.badgeData?.progressive ? level : selectedAchievementBadge.level}
          </span>
        </p>
      ) : (
        <>
          <div className={`icon-container ${selectedAchievementBadge?.isUnlocked ? '' : 'locked'}`}>
            {PadlockIconMini(selectedAchievementBadge?.isUnlocked)}
          </div>
          <p className={`with-icon ${selectedAchievementBadge?.isUnlocked ? '' : 'locked'}`}>
            {selectedAchievementBadge?.isUnlocked ? 'Unlocked' : 'Locked'}
          </p>
        </>
      )}
    </div>
  );
};

BadgeCurrentLevel.propTypes = {
  level: PropTypes.string,
  selectedAchievementBadge: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.number,
    isUnlocked: PropTypes.bool,
    badgeData: {
      ctaUrl: PropTypes.string,
      progressive: PropTypes.bool,
      style: {
        glowColorCode: PropTypes.string,
      },
      subtype: PropTypes.string,
      textLocked: PropTypes.string,
      textUnlocked: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    },
  }),
};

export default BadgeCurrentLevel;
